import * as React from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'
import { Seo } from '@/components/Seo'
import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { TitleWrapper } from '@/styles/common'

export default function DonePage() {
  const breadCrumbs = [
    { name: 'トップ', path: '/' },
    { name: 'お問い合わせ', path: '/contact' },
    { name: 'お問い合わせを受け付けました', path: '' },
  ]

  return (
    <Layout isForm>
      <Seo
        title="お問い合わせ完了 - お問い合わせ"
        canonical="https://su-pay.jp/contact/phone-change/done/"
        noIndex
      />
      <Title as="h1" bg>
        お問い合わせ
      </Title>
      <PageContents>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            お問い合わせを受け付けました
          </Title>
        </TitleWrapper>
        <Contents>
          <p>お問い合わせいただきありがとうございます。</p>
          <p>確認のため、自動返信メールをお送りさせていたしました。</p>
          <p>
            担当者よりご返信させていただきますので、今しばらくお待ちください。
          </p>
          <br />
          <p>万が一、24時間経っても自動返信メールが届かない場合、</p>
          <p>大変お手数ですが、再度お問い合わせください。</p>
        </Contents>
      </PageContents>
    </Layout>
  )
}

const PageContents = styled.div`
  width: ${(props) => props.theme.containerWidth};

  margin: 30px auto 0;

  @media screen and (max-width: 1047px) {
    width: 100%;

    padding: 0 ${(props) => props.theme.containerMargin};
    margin: 12px auto 0;
  }
`

const Contents = styled.div`
  font-size: 18px;

  @media screen and (max-width: 1047px) {
    font-size: 14px;
  }
`
